import { FC, useEffect, useRef } from 'react';
import {
  Engine,
  Scene as BabylonScene,
  EngineOptions,
  SceneOptions,
} from '@babylonjs/core';
import classNames from 'classnames';

import './Scene.scss';

export type SceneProps = {
  antialias?: boolean;
  engineOptions?: EngineOptions;
  adaptToDeviceRatio?: boolean;
  sceneOptions?: SceneOptions;
  onRender?: (scene: BabylonScene) => void;
  onSceneReady?: (scene: BabylonScene) => void;
} & JSX.IntrinsicElements['canvas'];

export const Scene: FC<SceneProps> = ({
  antialias,
  engineOptions,
  adaptToDeviceRatio,
  sceneOptions,
  onRender,
  onSceneReady,
  ...rest
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const { current: canvas } = canvasRef;

    if (!canvas) {
      return;
    }

    const engine = new Engine(
      canvas,
      antialias,
      engineOptions,
      adaptToDeviceRatio,
    );
    const scene = new BabylonScene(engine, sceneOptions);

    if (scene.isReady()) {
      onSceneReady?.(scene);
    } else {
      scene.onReadyObservable.addOnce((scene) => onSceneReady?.(scene));
    }

    engine.runRenderLoop(() => {
      onRender?.(scene);
      scene.render();
    });

    const resize = () => {
      scene.getEngine().resize();
    };

    if (window) {
      window.addEventListener('resize', resize);
    }

    return () => {
      scene.getEngine().dispose();

      if (window) {
        window.removeEventListener('resize', resize);
      }
    };
  }, [
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
  ]);

  return (
    <canvas
      className={classNames('scene', rest.className)}
      ref={canvasRef}
      {...rest}
    />
  );
};
