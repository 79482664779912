import { FC } from 'react';
import { Link } from 'react-router-dom';

import './Welcome.scss';

import logo from '../assets/img/logo.svg';

export const Welcome: FC = () => {
  return (
    <div className="welcome">
      <img src={logo} alt="" className="welcome__logo" />
      <ul className="welcome__list">
        <li className="welcome__item">
          <Link to="demo" className="welcome__link">
            Demo
          </Link>
        </li>
      </ul>
    </div>
  );
};
