import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { SessionProvider } from '../utils/context';

import './Gatekeeper.scss';

import start from '../assets/img/start.svg';
import unsupported from '../assets/img/unsupported.svg';

export type GatekeeperProps = PropsWithChildren<{}>;

export const Gatekeeper: FC<GatekeeperProps> = ({ children }) => {
  const [isSupported, setIsSupported] = useState<boolean>(false);
  const [isImmersive, setIsImmersive] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const session = useRef<XRSession | null>(null);

  useEffect(() => {
    if (navigator.xr) {
      navigator.xr.isSessionSupported('immersive-ar').then((supported) => {
        setIsSupported(supported);
      });
    }
  });

  return (
    <div
      className={classNames('gatekeeper', {
        'gatekeeper--inactive': !isActive,
      })}
    >
      {isActive ? (
        <SessionProvider
          value={{
            session: session.current,
            isSupported,
            isImmersive,
          }}
        >
          {children}
        </SessionProvider>
      ) : (
        <>
          <img
            src={isSupported ? start : unsupported}
            alt=""
            className="gatekeeper__splash"
          />
          <button
            className={classNames('gatekeeper__button', {
              'gatekeeper__button--unsupported': !isSupported,
              'gatekeeper__button--supported': isSupported,
              'gatekeeper__button--disabled': !isSupported,
            })}
            disabled={!isSupported}
            onClick={() => {
              if (navigator.xr && isSupported) {
                navigator.xr
                  .requestSession('inline', {
                    requiredFeatures: [],
                    optionalFeatures: ['hand-tracking'],
                  })
                  .then((newSession) => {
                    session.current = newSession;
                    setIsImmersive(
                      Boolean(
                        'isImmersive' in newSession && newSession.isImmersive,
                      ),
                    );
                    setIsActive(true);
                  });
              }
            }}
          >
            {isSupported ? 'Start' : 'VR unsupported'}
          </button>
        </>
      )}
    </div>
  );
};
