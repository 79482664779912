import { createContext, useContext } from 'react';

export type Session = {
  session: XRSession | null;
  isSupported: boolean;
  isImmersive: boolean;
};

const SessionContext = createContext<Session>({
  session: null,
  isSupported: false,
  isImmersive: false,
});

export const useSession = (): Session => useContext(SessionContext);

export const SessionProvider = SessionContext.Provider;
