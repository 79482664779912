import { FC, useRef } from 'react';
import {
  FreeCamera,
  Vector3,
  HemisphericLight,
  Mesh,
  StandardMaterial,
  Color3,
  CreateSphere,
  CreateGround,
} from '@babylonjs/core';

import { Gatekeeper } from '../components/Gatekeeper';
import { Scene as SceneComponent } from '../components/Scene';

export const Demo: FC = () => {
  const boxRef = useRef<Mesh | null>(null);

  return (
    <Gatekeeper>
      <SceneComponent
        antialias
        onSceneReady={async (scene) => {
          // This creates and positions a free camera (non-mesh)
          const camera = new FreeCamera(
            'camera1',
            new Vector3(0, 5, -10),
            scene,
          );

          // This targets the camera to scene origin
          camera.setTarget(Vector3.Zero());

          const canvas = scene.getEngine().getRenderingCanvas();

          // This attaches the camera to the canvas
          camera.attachControl(canvas, true);

          // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
          const light = new HemisphericLight(
            'light',
            new Vector3(0, 1, 0),
            scene,
          );
          // Default intensity is 1. Let's dim the light a small amount
          light.intensity = 0.7;

          // Sphere / Ball
          const sphere = CreateSphere(
            'sphere',
            {
              segments: 32,
              diameter: 1,
            },
            scene,
          );
          sphere.position.y = 2;
          sphere.position.z = 5;

          const bla = new StandardMaterial('bla');
          bla.alpha = 1;
          bla.diffuseColor = Color3.FromHexString('#00FF00');
          sphere.material = bla;

          // Ground
          const ground = CreateGround(
            'ground',
            {
              width: 6,
              height: 6,
            },
            scene,
          );

          // Add dirt material to ground
          const dirt = new StandardMaterial('dirt');
          dirt.alpha = 1;
          dirt.diffuseColor = Color3.FromHexString('#8B4513');
          ground.material = dirt;

          // Start the XR experience
          await scene.createDefaultXRExperienceAsync({
            uiOptions: {
              sessionMode: 'immersive-ar',
            },
          });
        }}
        onRender={(scene) => {
          if (boxRef.current) {
            const deltaTimeInMillis = scene.getEngine().getDeltaTime();
            const rpm = 10;
            boxRef.current.rotation.y +=
              (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
          }
        }}
      />
    </Gatekeeper>
  );
};
